body {
  margin: 0;
  padding: 0;
  width: 100%;
}
html {
  margin: 0;
  padding: 0;
  width: 100%;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

.logo-container {
  background-color: #e2ebed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1200px;
  width: inherit;
  height: 60px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 7px 20px -5px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.logo {
  padding-top: 5px;
  height: calc(100% - 10px);
  padding-bottom: 5px;
  padding-left: 5px;
}

.github-link {
  height: 100%;
}

.github {
  margin-top: 10px;
  margin-right: 10px;
  height: calc(100% - 17px);
}

section {
  padding: 50px 5px 50px 5px;
}

section .imgs-container {
  display: flex;
  justify-content: center;
}

section .imgs-container img {
  padding-right: 20px;
  padding-left: 20px;
  width: 70px;
  height: 70px;
  overflow: hidden;
}

section h1 {
  font-weight: normal;
  padding-bottom: 10px;
  text-align: center;
}

.try-now-btn-container {
  margin-top: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #666666;
  font-size: 24px;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}

.button:hover {
  background-color: #f6f6f6;
}

.button:active {
  position: relative;
  top: 1px;
}

.app-section {
  padding-top: 100px;
}

.app-section h1 {
  padding-bottom: 20px;
}

.app-section img {
  max-width: 100%;
}

.app-section .arrows {
  width: 100%;
  text-align: center;
}

.db-list-section {
  background-color: #f7f7f7;
}

.export-section {
  background-color: #f7f7f7;
}

.footer {
  padding: 10px;
  background-color: #888;
  color: #fff;
}

.footer a {
  color: #fff;
  float: right;
  margin-left: 10px;
}
